<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <list-page
        :columns="columns"
        :headers="headers"
        :show-add-button="false"
        sort="id"
        :descending="true"
        title="Delivery Email Logs"
        icon="mdi-truck-delivery"
        stateendpoint="deliveryEmailLog.deliveryEmailLogs"
        :actions="actions"
        :show-default-action-buttons="false"
        :show-clear-button="true"
        clear-button-text="Clear Delivery Email Logs"
    />
  </div>
</template>
<script>
import ListPage from "../../components/ListPage";

export default {
  name: 'DeliveryEmailLogs',
  metaInfo: {
    title: 'webexpressmedia.com',
    titleTemplate: 'Admin Dashboard - Delivery Email Logs | %s',
    meta: [
      { name: 'robots', content: 'noindex' },
      { name: 'googlebot', content: 'noindex' }
    ]
  },
  components: {ListPage},
  data() {
    return {
      headers: [
        {
          text: 'Logs',
          value: 'logs'
        },
        {
          text: 'Error At',
          align: 'right',
          value: 'created_at'
        },
      ],
      columns: [
        {value: 'logs'},
        {value: 'action'}
      ],
      actions: {
        load: 'loadDeliveryEmailLogs',
        clearData: 'clearDeliveryEmailLogs'
      }
    }
  }
}
</script>
